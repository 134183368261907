.trading-panel-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-text {
  color: lightyellow;
  font-size: 20px;
}

.trading-panel {
  width: 90%;
  height: 100%;
  overflow-y: auto;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.info-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-section {
  margin-bottom: 20px;
}

.position-size-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-label {
  color: lightyellow; /* Set light yellow color for text labels */
  margin-right: 10px;
}

.button-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.positions-section {
  display: flex;
  flex-direction: column;
}

.positions-scrollable,
.closed-positions-scrollable {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.positions-scrollable {
  padding: 10px;
  border-radius: 5px;
}

.closed-positions {
  max-height: 200px; /* Set a maximum height to prevent overflow */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.closed-positions-scrollable {
  padding: 10px;
  border-radius: 5px;
}

.position {
  width: 100%;
  box-sizing: border-box;
}

.trade-button {
  font-size: 1.5rem;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.buy-button {
  background-color: #4caf50;
  width: 150px;
  color: white;
}

.sell-button {
  width: 150px;
  background-color: #f44336;
  color: white;
}

.positions-scrollable::-webkit-scrollbar,
.closed-positions-scrollable::-webkit-scrollbar {
  width: 12px;
}

.positions-scrollable::-webkit-scrollbar-track,
.closed-positions-scrollable::-webkit-scrollbar-track {
  background: #333;
}

.positions-scrollable::-webkit-scrollbar-thumb,
.closed-positions-scrollable::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 6px;
  border: 3px solid #333;
}

/* Firefox scrollbar customization (works on recent versions of Firefox) */
.positions-scrollable {
  scrollbar-width: thin;
  scrollbar-color: #666 #333;
}

.closed-positions-scrollable {
  scrollbar-width: thin;
  scrollbar-color: #666 #333;
}

.input-label {
  color: lightyellow; /* Set light yellow color for text labels */
  margin-right: 10px;
}

.position-size-container .max-label {
  margin-left: 20px; /* Add margin to the left of the Max label */
}