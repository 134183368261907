
.orderBook{
  font-weight: bold;
  color: azure;
}

.ask-cup, .bid-cup {
  width: 100%;
}

.ask-row, .bid-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 4px;
  margin: 2px 0;
  border-radius: 3px;
}

.current-price {
  font-size: 1.5em;
  margin: 10px 0;
  color: whitesmoke;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  border-radius: 3px;
}

.ask-row .fill {
  background-color: rgba(255, 0, 0, 0.2);
}

.bid-row .fill {
  background-color: rgba(0, 255, 0, 0.2);
}

.text {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bid-ask-cup {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(5,30,15,0.6);
  color: wheat;
  padding: 5px;
  border-radius: 5px;
  height: 100%; /* Take full height of the container */
  font-family: Arial, sans-serif;
}