/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: rgba(0, 100, 100, 0.75);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 50%;
  height: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 2px solid black; /* Added black border */
}
