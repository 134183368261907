.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 150%;
}

/* Ticker Css */
#ticker{
  
  width: 100%;
  height: 4rem;
  background: black;
  display:flex;
  justify-content:space-between;
  align-item:center;
  
}

#fixed-bar{
  padding-top:10px;
  width: 14.50%;
  height: 4rem;
  background-color: #f44336;  
  font-size:30px;
  color:white;
}


.news-container {
  width: 85%;
  height: 4rem;
  background-color: #4caf50;
  color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  color:black;
  font-size:30px;
  
}

.news-container ul {
  display: flex;
  list-style: none;
  margin: 0;
  animation: scroll 25s infinite linear;
}

.news-container ul li {
  white-space: nowrap;
  padding: 10px 24px;
  color: white;
  position: relative;
}

.news-container ul li::after {
  content:"";
  width: 3px;
  height: 5rem;
  background: black;
  position: absolute;
  top: 0;
  right: 0;
}

.news-container ul li:last-child::after {
  display: none;
}

/* ANIMATION */
@keyframes scroll {
  from {
      transform: translateX(100%);
  }

  to {
      transform: translateX(-2380px);

  }
}


/* Ticker Css */

.content {
  display: flex;
  width: 100%;
  height: calc(100vh - 2 * 100px); /* Adjusted to account for the banners */
  box-sizing: border-box;
  background-color: black;
}

.chart-container {
  flex: 10; /* 80% of the available space */
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.tv-lightweight-charts {
  width: 100%;
  height: 100%;
}

.trading-panel {
  flex: 1; /* Adjusted to a smaller width */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: black;
}

.bid-ask-cup-container {
  flex: 3; /* 20% of the available space */
  padding: 5px;
  box-sizing: border-box;
  border-left: 1px solid black;
}

.chart-container, .menu-container, .bid-ask-cup-container {
  margin: 5px; /* Optional: Add some margin around the containers */
}


